import React, { useState } from 'react';
import './Carousel.css';
import birdImage from "../../../img/type.svg";


import { Container } from '@material-ui/core';
import useLocale from '../../../lang/useLocale';

const Carousel = (props) => {
    const { getLocale } = useLocale();
    const [activeIndex, setActiveIndex] = useState(0);

    const handleImageClick = (index) => {
        if (index !== activeIndex) {
            setActiveIndex(index);
        }
    };

    const getTransformStyle = (index) => {
        const position = (index - activeIndex + 3) % 3;

        switch (position) {
            case 0:
                return { transform: 'translateX(0) scale(1)', zIndex: 2, opacity: 1 };
            case 1:
                return { transform: 'translateX(100%) scale(0.8)', zIndex: 1, opacity: 0.6 };
            case 2:
                return { transform: 'translateX(-100%) scale(0.8)', zIndex: 1, opacity: 0.6 };
            default:
                return { transform: 'translateX(0) scale(0.5)', zIndex: 0, opacity: 0 };
        }
    };
    return (
        <div>
            <div className={"meet"}>
                <h4>{getLocale("knowmoreaboutpengiunes")}</h4>
            </div>
            {Array.isArray(props.data?.plugin) &&
                <div>
                    <div className="carousel">
                        <div className="carousel-inner">
                            {props.data?.plugin.slice(0, 3).map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
                                    style={getTransformStyle(index)}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <img src={item.image} alt={`${item.id}`} />
                                </div>
                            ))}

                        </div>
                    </div>
                    <Container>
                        <div className="carousel-title">
                            <img src={birdImage} alt={`title`} />
                            <h3>{props.data?.plugin[activeIndex].name}</h3>
                        </div>
                        <div className="carousel-description">
                            <p>{props.data?.plugin[activeIndex].description}</p>
                        </div>
                    </Container>
                </div >
            }
        </div >
    );
};

export default Carousel;

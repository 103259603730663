import React from "react";
import styles from "./video.module.scss";
import { Grid } from "@material-ui/core";
// import playIcon from "../../../img/playIcon.svg";

function Video(props) {
  // const [placeHolder, setPlaceHolder] = useState(true);
  // function handlePlaceHolder() {
  //   setPlaceHolder(!placeHolder);
  // }
  return (
    <div className={`${styles.containerVideoSchool}`}>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12} md={12} className={`${styles.containerVideo}`}>
          {/* {placeHolder ? (
            <Fragment>
              <div onClick={handlePlaceHolder} className={styles.wrap}>
                <img src={playIcon} alt="icon" className={styles.icon} />
                <img
                  src={props.img}
                  alt="video"
                  className={styles.placeHolder}
                />
              </div>
            </Fragment>
          ) : ( */}
          <iframe
            title="video"
            width="100%"
            height="315"
            src={props.videoUrl}
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen>
          </iframe>
          {/* )} */}
        </Grid>
      </Grid>
    </div>
  );
}
export default Video;

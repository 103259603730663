import React, { useEffect, useState } from "react";
import styles from "./groupPackages.module.scss";
import useLocale from "../../lang/useLocale";
// import corporateBenefits from "../../img/corporateBenefitsPanal.jpg";
import Banner from "../General/headerPanel/headerPanel";
// import OurAttractions from "../General/Our-Attractions/Our-Attractions"
// import SocialSection from "../General/social-section/social-section";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import TabsForGroupPackages from "./subComponents/tabsForGroupPackages/tabsForGroupPackages";
import lang from "../../redux/reducers/lang";

export default function GroupPackages() {
  const { getLocale } = useLocale();
  const dataGroupPackages = useSelector((state) => state.webReducer);
  const [groupPackagesData, setGroupPackagesData] = useState([]);
  const { data } = dataGroupPackages;
  // console.log(groupPackagesData);
  useEffect(() => {
    if (Array.isArray(data)) {
      setGroupPackagesData(data[4]);
    }
  }, [data, groupPackagesData]);

  return (
    <div className={styles.GroupPackages}>
      <Helmet>
        <meta
          name="description"
          content={getLocale(
            "Visit ski Egypt to know packages and enjoy booking our birthday group packages, school trip, or corporate group Package."
          )}
        />
        <title>
          {getLocale("Visit SKi to Know Group Packages | SKI Egypt")}
        </title>
        <link
          rel="canonical"
          href={`https://skiegy.com/${lang}/groupPackages`}
        />
      </Helmet>
      <Banner
        panal={groupPackagesData.image}
        containerCard
        title={groupPackagesData.title}
        description={groupPackagesData.description}
      />
      <TabsForGroupPackages
        data={
          Array.isArray(groupPackagesData.sections) &&
          groupPackagesData.sections[0]
        }
      />
      {/* <OurAttractions /> */}
      {/* <SocialSection /> */}
    </div>
  );
}

import React, { useRef, useLayoutEffect } from "react";
// import styles from '../../Home/home.module.scss';
import Swiper from 'swiper';
import 'swiper/css';
export default function  SliderSwiper(props) {
	const containerRef = useRef(null); 
	const updateRef = useRef(null);
	const prevRef = useRef(null);          
	const nextRef = useRef(null);
	const bulletsRef = useRef(null);
	const { arrows, search, breakpoints, spaceBetween, autoplay, autoplayDisableOnInteraction, slidesPerView } = props.settings;

	useLayoutEffect(() => {            
		if (              
			props.mySwiper &&
			containerRef.current &&
			prevRef.current &&
			nextRef.current &&
			bulletsRef.current &&
			props.settings
		) {
			updateRef.current = new Swiper(containerRef.current, {
				breakpoints: breakpoints ? breakpoints : {},
				spaceBetween: spaceBetween ? spaceBetween : {},
				arrows: arrows ? true : false,
				autoplay: autoplay ? autoplay : {},
				autoplayDisableOnInteraction: autoplayDisableOnInteraction ? autoplayDisableOnInteraction : {},
				slidesPerView: slidesPerView ? slidesPerView : {},
				navigation: {
					nextEl: nextRef.current,
					prevEl: prevRef.current,
				},
				pagination: {
					el: '.swiper-pagination',
					// el: bullets ? bulletsRef.current : null,
					type: "bullets",
					renderProgressbar: null,
					clickable: true,
				}
			});
			props.mySwiper.current = updateRef.current;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerRef, prevRef, nextRef, bulletsRef]);

	useLayoutEffect(() => {
		if (props.mySwiper) {
			// let img1 = props.mySwiper.current.slides[0]?.querySelector("img")?.getAttribute("src");
			// props.mySwiper.current.pagination.bullets[0].style.cssText = `background-image: url(${img1}) !important; background-size: cover !important; width: 40px !important; height: 40px !important; transform: translateY(3px);`;
			// props.mySwiper.current.on("slideChange", function () {
			// 	let img = props.mySwiper.current.slides[props.mySwiper.current.activeIndex]
			// 		.querySelector("img")
			// 		.getAttribute("src");
			// 	if (img) {
			// 		props.mySwiper.current.pagination.bullets[props.mySwiper.current.previousIndex].style.cssText = ``;
			// 		props.mySwiper.current.pagination.bullets[
			// 			props.mySwiper.current.activeIndex
			// 		].style.cssText = `background-image: url(${img}) !important; background-size: cover !important;   width: 40px !important; height: 40px !important; transform: translateY(3px);`;
			// 	}
			// });
		}
	}, [props.mySwiper, props.settings.thumbs]);
	useLayoutEffect(() => {
		if (props.children) {
			updateRef.current.update();
		}
	}, [props.children]);

	return (
		// <div className={`swiper-container ${props.containerClass ? props.containerClass : ""}`} ref={containerRef}>
		// 	<div className="swiper-wrapper">{props.children}</div>
		// 	<div className="swiper-pagination" ref={bulletsRef}></div>
		// 	<div className={`${!arrows ? "hideArrows" : "showArrows"} swiper-button-prev`} ref={prevRef}></div>
		// 	<div className={`${!arrows ? "hideArrows" : "showArrows"} swiper-button-next`} ref={nextRef}></div>
		// 	<div className="swiper-scrollbar"></div>
		// 	{search}
		// </div>


		<div className={`swiper ${props.containerClass ? props.containerClass : ""}`} ref={containerRef} >
			<div className={`swiper-wrapper`} >{props.children}</div>
			<div className="swiper-pagination" ref={bulletsRef}></div>
			<div className={`${!arrows ? "hideArrows" : "showArrows"} swiper-button-prev`} ref={prevRef}></div>
			<div className={`${!arrows ? "hideArrows" : "showArrows"} swiper-button-next`} ref={nextRef}></div>
			<div className="swiper-scrollbar"></div>
			{search}

		</div>

	);
}

SliderSwiper.defaultProps = {
	settings: {},
};

import React, { useLayoutEffect, useState } from "react";
import styles from "../penguins.module.scss";
import { useSelector } from "react-redux";
import marchEn from "../../../img/march-en.png";
import marchAr from "../../../img/march-ar.png";
import marchEnMobile from "../../../img/march-en-mobile.png";
import marchArMobile from "../../../img/march-ar-mobile.png";

export default function NewMarch() {
  const lang = useSelector((state) => state.lang);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 720);

  useLayoutEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 720);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImageSrc = () => {
    if (isMobile) {
      return lang === "ar" ? marchArMobile : marchEnMobile;
    }
    return lang === "ar" ? marchAr : marchEn;
  };

  return <img src={getImageSrc()} alt="" className={styles.placeHolder} />;
}
